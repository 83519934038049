body {
    background: #fafafa;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333;
}

/* autocomplete */

.highlight {
	background: yellow;
}


/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Developement Ribbon
========================================================================== */
.ribbon {
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(-45deg);
    ms-transform: rotate(-45deg);
    o-transform: rotate(-45deg);
    overflow: hidden;
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(-45deg);
    white-space: nowrap;
    width: 15em;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: #ccc
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
    font-size: 35px;
}

.logo .logo-img {
    height: 45px;
    display: inline-block;
}

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
    margin: 50px auto 0 auto;
    width: 300px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    background-color: #eee;
    border-radius: 6px;
    padding: 60px;
}

.hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

.hipster {
    display: inline-block;
    width: 347px;
    height: 70px;
    background: url("../images/partner.jpg") no-repeat center top;
    background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
    .hipster {
        background: url("../images/partner.jpg") no-repeat center top;
        background-size: contain;
    }
}

.hipsterProgetti {
    display: inline-block;
    width: 200px;
    height: 200px;
    background: url("../images/IconaProgetti.png") no-repeat center top;
    background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
    .hipsterProgetti {
        background: url("../images/IconaProgetti.png") no-repeat center top;
        background-size: contain;
    }
}

.hipsterAP {
    display: inline-block;
    width: 200px;
    height: 200px;
    background: url("../images/IconaAP.png") no-repeat center top;
    background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
    .hipsterAP {
        background: url("../images/IconaAP.png") no-repeat center top;
        background-size: contain;
    }
}
.hipsterLogo {
    display: inline-block;
    width: 450px;
    height: 75px;
    background: url("../images/IconaScritta.png") no-repeat center top;
    background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media
only screen and (-webkit-min-device-pixel-ratio: 0),
only screen and (   min--moz-device-pixel-ratio: 0),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 0),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
    .hipsterLogo {
        background: url("../images/IconaScritta.png") no-repeat center top;
        background-size: contain;
    }
}


/* ==========================================================================
Generic styles
========================================================================== */
.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break:break-all;
}

.voffset  { margin-top: 2px; }
.voffset1 { margin-top: 5px; }
.voffset2 { margin-top: 10px; }
.voffset3 { margin-top: 15px; }
.voffset4 { margin-top: 30px; }
.voffset5 { margin-top: 40px; }
.voffset6 { margin-top: 60px; }
.voffset7 { margin-top: 80px; }
.voffset8 { margin-top: 100px; }
.voffset9 { margin-top: 150px; }

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
  cursor: pointer;
}

.hand {
    cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display:inline;
    list-style:none;
    margin:0;
    margin-left:15px;
    padding:0;
    vertical-align:2px;
}

.point:last {
    margin:0 !important;
}
.point {
    background:#DDD;
    border-radius:2px;
    display:inline-block;
    height:5px;
    margin-right:1px;
    width:20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert{
    text-overflow: ellipsis;
}
.alert pre{
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding { padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min { width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: row;
   flex-direction: row;
   -webkit-justify-content: flex-end;
   justify-content: flex-end;
}

.jh-table > tbody > tr > td {
    /* Align text in td verifically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
    margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details > dt {
        margin-bottom: 10px;
    }

    .dl-horizontal.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup  > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup  > li > div.uib-datepicker > table:focus {
    outline: none;
}



/* jhipster-needle-css-add-main JHipster will add new css style */

/* table */

.jh-sort {
	text-decoration: underline;
}

.jh-sort:hover {
	background: #DDDDDD;
}

.jh-sorted {
	background: rgba(141, 192, 219, 0.25);
}

table .tablesort-sortable {
	text-decoration: underline;
}

table .tablesort-sortable:after {
 	/* display: none!important;  */
}

table .tablesort-sortable:hover {
	background: #DDDDDD;
}

.jh-table th .glyphicon {
	float: right;
}

/* buttons */

.btn-prm {
	background-color: blue;
}

.btn-val {
	background-color: fuchsia;
}

.btn-cer {
	background-color: lime;
}

.btn-raz {
	background-color: olive;
}

/* direttive */
.checkconsuntivo-verde:before {
	content:"\e084";
	color: green;
}

.checkconsuntivo-giallo:before {
	content:"\e101";
	color: orange;
}

.checkconsuntivo-unknown:before {
	content:"\e085";
	color: #999999;
}

.checkconsuntivo-rosso:before {
	content:"\e083";
	color: red;
}

.checkallegati-completo:before {
	content:"\e084";
	color: green;
}

.checkallegati-validato:before {
	content:"\e084";
	color: blue;
}

.checkallegati-incompleto:before {
	content:"\e083";
	color: red;
}

.checkallegati {
	font-size: 25px;
}

.checkvalidazione-true:before {
	content:"\e084";
	color: green;
}

.checkvalidazione-false:before {
	content:"\e083";
	color: red;
}

.checkcertificazione-true:before {
	content:"\e084";
	color: green;
}

.checkcertificazione-false:before {
	content:"\e083";
	color: red;
}

.checkcertificazione {
	font-size: 25px;
}

.checkvalidazione {
	font-size: 25px;
}

.checkconsuntivo {
	font-size: 25px;
}

.allegatoicon {
	font-size: 25px;
}

.allegatoicon-truetrue:before, .allegatoicon-truefalse:before {
	content:"\e013";
	color: green;
}

.allegatoicon-falsetrue:before {
	content:"\e014";
	color: red;
}

.allegatoicon-falsefalse:before {
	content:"\2212";
	color: orange;
}

.scadicon {
	font-size: 25px;
}

.scadicon-completo-true:before, .scadicon-completo-false:before {
	content:"\e013";
	color: green;
}

.scadicon-validato-true:before, .scadicon-validato-false:before {
	content:"\e013";
	color: blue;
}

.scadicon-incompleto-true:before {
	content:"\e014";
	color: red;
}

.scadicon-incompleto-false:before {
	content:"\2212";
	color: orange;
}

.tickedbox {
	font-size: 25px;
}

.tickedbox-true:before {
	content:"\e013";
	color: green;
}

.tickedbox-false:before {
	content:"\2212";
	color: orange;
}

/* progetto */

th .glyphicon {
	margin-left: 10px;
}

#progettosx img {
	max-width: 100%;
}

input[type="checkbox"] {
    width: 25px;
}

.aziendaLogo td {
    padding: 3px;
}

.aziendaLogo img {
	max-width: 50px;
	max-height: 50px;
}

.hidden-har, .hidden-has, .hidden-haa, .hidden-nc  {
  display: none !important;
}

.sal-export-buttons {
	margin-top: 5px;
}

/* loader */

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

#loaderBg {
	background: white none repeat scroll 0 0;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.dd-with-button {
	padding-bottom: 15px;
}

/**** foglio presenze ****/

.g_in, .g_out { 
  font-size: 9px;
  width: 18px;
  height: 16px;
  overflow: hidden;
  text-align: center;
}

.g_in { 
	border: none;
}

.g_out { 
  background: white; 
  pointer-events: none;
  padding-top: 2px;
}

input.g_in:focus + .g_out { 
	display: none; 
}

#presenze { 
	border-collapse: collapse; 
/* 	margin: auto; */
}

#presenze td > div {
  width: 18px;
  height: 16px;
  overflow: hidden;
}
 
#presenze td { 
	border: 1px solid #EEE; 
}

#presenze .column-label  > td, #presenze .row-label {
  text-align: center;
  background: #EEE;
}
#presenze .row-label { 
	width: 30px; 
}

#presenze .firstcell {
	background: white!important;
	border: none;
}

/* .dettaglioPresenza { */
/* 	position: relative; */
/* 	overflow: scroll; */
/* } */

#presenze td {
	border-color: #777;
	border-spacing: 1px;
	border-width: 1px;
	font-size: 10px
}

.foglioPresenzaDetail .g_out, .foglioPresenzaDetail #presenze .firstcell {
	background: transparent!important; 
}

.timesheet-overflow {
	overflow: auto; 
	position: relative;
}

.foglioPresenzaDetail {
	padding-left: 3px!important;
}

.foglioPresenzaDetail #presenze { 
	margin: inherit;
}

.dateNE, .dateNE * {
	background: #afaeb0!important;
	border-color: #afaeb0!important;
}

.dateW, .dateW * {
	background: #fcd685!important;
	border-color: #fcd685!important;
}

.dateH, .dateH * {
	background: #ff9e3c!important;
	border-color: #ff9e3c!important;
}

.checkUB .dateE {
	background-color: red!important;
	color: white;
}

.ts_readonly td div {
	background: #EEEEEE;
	border-color: #EEEEEE;	
} 


/** cookies **/
.cl-banner {
	background: #448!important;
	opacity: 0.9;
} 

/** header app */
.app-name {
	font-family: 'Lucida Console', Monaco, monospace;	
	color: #337ab7;
}

.app-name2 {
	font-family: 'Lucida Console', Monaco, monospace;	
}

/** home **/
.homebutton {
	margin: 5px 0;
}

/* tooltip */
.tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    background-color: rgb(97, 97, 97);
    border-radius: 4px;
}

.tooltip.top .tooltip-arrow {
    border-top-color: rgb(97, 97, 97)!important;
}

.inline-help-no-float .inline-help-icon {
    float: none;
}

.inline-help-icon {
	float: right;
}

.inline-help-icon:hover {
	color: blue;
}

.linktohelp {
	float: right;
	font-size: 22px;
}

.popover {
	z-index: 1051!important;
}

/* progress bar rendicontazione */
.progress {
	background-color: lightgrey;
	margin: auto 0;
}

.progress-full {
	background-color: green;
}
.progress-high {
	background-color: orange;
	color: black;
}
.progress-low {
	background-color: red;
	color: black;	
}
.progress-nd {
	margin-left: 5px;
	color: black;
}

/* json editor  */
.jsoneditor-search input {
    color: blue;
}

/*** altro ****/

